@mixin maxWidth {
  // max-width: 1024px;
}

@mixin flex($dir: row, $justifyContent: flex-start, $alignItems: stretch, $wrap: nowrap) {
  display: flex;
  flex-direction: $dir;
  justify-content: $justifyContent;
  align-items: $alignItems;
  flex-wrap: $wrap;
}
