@media only screen and (max-width: 500px) {
  .joinClubContainer {
    width: 100% !important;
  }

  .joinModalexitIconContainer {
    width: 100% !important;
  }
}

.joinModalContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding:20px;
  margin:20px;
  align-items: center;
}

.joinClubContainer {
  background-color: white;
  padding:20px;
  margin: 20px; 
  width: 350px;
  height: fit-content;
}

.outterJoinPickModalContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.innerJoinPickModalContainer {
  width: 350px;
  display: flex;
  flex-direction: column;
}

.joinModalTitleContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    
}

.joinModalClubTitleText {
  padding-left: 5px;
  font-size: 20px;
  font-weight: bold;
}


.joinModalQuestionText {
  font-size: 20px;
  font-weight: bold;
}

.joinModalInfoText{
  text-align: center;
  padding-top: 15px;
}

.joinModalCodeInput {
  padding-top: 15px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.joinModalexitIconContainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-end !important; 
  width: 350px;
}