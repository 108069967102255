.rulesContainer {
  background-color: white;
  padding: 20px;
  margin: 20px;
  overflow-y: auto !important;
  height: 85%;
}

.outterPickModalContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding-top: 40px;
}

.innerPickModalContainer {
  width: 700px;
}

.myClubsItem {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-top: 5px;
  padding-bottom: 6px;
  padding-left: 10px;
  padding-right: 10px;
  border-bottom: 1px #DCDCDC solid;
}

.myClubsItemTitleContainer {
  display: flex;
  flex-direction: row;
}

.myClubsItemTitle {
  padding-left: 5px;
  font-size: 16px;
  font-weight: 600;
}

.deleteClubText {
  font-family: "DIN-Pro";
  color: #E30613;
}

.joinClubText {
  font-family: "DIN-Pro";
  text-decoration: underline;
  color: #11448F;
  font-size: 16px;
}

.allClubsLetter {
  background-color: #242B49;
  padding-top: 2px;
  padding-bottom: 2px;
  padding-left: 10px;
  padding-right: 10px;
  font-weight: bold;
  color: #ffffff
}

.allClubsItemContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-top: 7px;
  padding-bottom: 8px;
  padding-left: 10px;
  padding-right: 10px;
  border-bottom: 1px #DCDCDC solid;

  .clubTitle {
    font-weight: 600;
  }

}

.myClubsTitle {
  font-weight: bold;
  padding-top: 8px;
  padding-bottom: 8px;
  font-size: 22px;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.myClubsListContainer {
  padding-bottom: 20px;
}

.allClubslistContainer {
  max-height: 700px;
  overflow: auto;
}

.loadingContainerInClubpickModal {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.clubSearcAndTitleContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  margin-top: 10px;

  .availableClubs {
    display: flex;
    flex: 1;
    font-weight: bold;
    padding-top: 8px;
    padding-bottom: 8px;
    font-size: 22px;


  }

  .clubSearch {
    width: 100%;
  }

  .clubSearchField {
    width: 100%;
    margin-top: 10px;
    font-size: 16px;
    padding-bottom: 10px;
  }
}

.extraSpaceAfterLetterItems {
  margin-bottom: 30px;
}
