$textGray: #717171;
$textBlue: #242b49;
$pBlue: #024da2;
$textWhite: #ffffff;
$textBlack: #262626;
$bannerBlue: #61a5fc;
$saleRed: red;
$menuRed: #e6384a;
$delRed: #be4343;
$white: #ffffff;
$sanMarino: #3F51B5;
