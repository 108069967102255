// global setting to remove shadow from button - was used in secondary style
.MuiButton-contained {
  box-shadow: none !important;
}

:root {
  --app-height: 100%;
}

#root {
  height: 100%;
}

html,
body {
  padding: 0;
  margin: 0;
  width: 100vw;
  height: 100vh;
  height: var(--app-height);
}
