@media screen and (max-width: 550px) {

  .exitNewsletterButtonStyle {
    max-width:300px !important;
  }

}

@media screen and (max-width: 380px) {

  .exitNewsletterButtonStyle {
    max-width:200px !important;
  }

}

.newsletterPageWrapper {
  flex: 1;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.newsletterPageContentContainer {
  flex: 1;
  padding-bottom: 20px;
  padding-top: 20px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.newsletterCenterContainer {
  display:flex;
  justify-content: center;
  padding-top: 10px;
  padding-bottom: 10px;
}

.newsletterPageButtonContainer {
  display: flex;
  width: 100%;
  justify-content: center;
  padding-top: 20px;
}

.exitNewsletterButtonStyle {
  margin-top: 20px;
  width: 100%;
  max-width: 400px;
}