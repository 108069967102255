.userFormContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.formItem {
  padding-top: 5px !important;
  padding-bottom: 10px;
}

.userFormActionButtonContainer {
  padding-top: 10px;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.closeButtonStyle {
  padding-left: 5px;
}
