@media only screen and (max-width: 500px) {
  .deleteUserContainer {
    width: 100% !important;
  }

  .barcodeModalexitIconContainer {
    width: 100% !important;
  }

}



.deleteModalContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding:20px;
  margin:20px;
  align-items: center;
}

.deleteUserContainer {
  background-color: white;
  padding:20px;
  margin: 20px; 
  width: 440px;
  height: fit-content;
}


.outterDeleteUserModalContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.innerDeleteUserModalContainer {
  width: 440px;
  display: flex;
  flex-direction: column;
}

.deleteUserExitIconContainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-end !important; 
  // width: 440px;
}



.deleteUserModalTitle {
  align-self: center;
  font-weight: bold;
  padding-left: 10px;
}

.deleteUserCloseButton {
  align-self: center;
  padding-top: 20px;
  padding-left: 10px;
}

.barcodeTextValue {
  text-align: center;
  padding-bottom: 15px;
}

.deleteInfoTextContainer {
  display: flex;
  flex-direction: column;
  max-height: 210px;
  overflow-y: auto;
  padding-top: 20px;
  text-align: center;
}

.discountTextItem {
  text-align: center;
}

.discountTitleText {
  text-align: center;
  font-weight: bold;
}

.deleteModalButtonsContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.deleteIconContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.warningIconStyle {
  color: red;
}