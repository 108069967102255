.mobileMenuModal {
 display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  justify-content: space-around;
}

.mobileMenuModalWrap {
  display: flex;
   flex-direction: column;
   justify-content: space-around;
   flex: 1.4
 }

 .mobileMenuModalWrapBottom {
  flex: 0.6
  
 }

.closeMobileMenuIconContainer {
  display: flex;
  flex-direction: row;
}
.clearIcon{
  color: white;
  height: 20px;
  align-content: center;
  align-items: center;
  align-self: center;
 /*  background-color: blueviolet; */
}

.mobileMenuTextOpen {
  color: rgb(255, 255, 255);
  font-size: 16px;
  font-family: 'DIN-Pro';
  font-weight: bold;
  text-align: right;
  letter-spacing: 0.5px;
  padding-left: 5px;
 /*  background-color: blue; */
  padding-top: 2px;
  padding-bottom: 2px;
}

.verticalItemsContainer{

  display: flex;
  flex-direction: column;

}

.verticalItem {
  color: rgb(255, 255, 255);
  font-size: 16px;
  font-family: 'DIN-Pro';
  font-weight: normal;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding-top: 30px;

}


.verticalItemSelected {
  color:red;
  font-size: 16px;
  font-family: 'DIN-Pro';
  font-weight: normal;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding-top: 30px;
}

.markedVerticalItem{
  background-color: red;
  height: 2px;
  width: 100%;
}

.unmarkedVerticalItem{
  height: 2px;
}

.verticalItemButton {
  display: flex;
  flex-direction: column;
  width: fit-content;
  justify-content: center;
  align-self: center;
}

.verticalMenuContainer{
  display: flex;
  flex-direction: row;
  justify-content: center;
}


