@media screen and (max-width: 500px) {
  .clubLogo {
    height: 30px !important;
  }
}

@media screen and (max-width: 300px) {
  .clubLogo {
    height: 20px !important;
  }
}

.layoutContainer {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.logoWrap {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.buttonsContainer {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  justify-content: space-around;
}

.buttonsWrap {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 80%;
  max-width: 400px;
  align-self: center;
}

.buttonText {
  font-weight: bold;
  font-family: "DIN-Pro";
}

.paddingButton {
  height: 20px;
}

.clubLogo {
  width: auto;
  height: 46px;
}

.logoContainer {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.MuiButton-outlined {
  border-radius: 25px !important;
  // font-weight: bold !important;
  font-size: 16px !important;
  letter-spacing: 0.5px !important;
}

// for login screen
.buttonsWrap {
  .MuiButton-outlined:hover {
    background-color: #e30f1a !important;
    border-color: #e30f1a !important;
  }

  .MuiButton-label {
    padding-top: 5px !important;
    padding-bottom: 5px !important;
  }
}
