@media only screen and (max-width: 500px) {
  .barcodeContainer {
    width: 100% !important;
  }

  .barcodeModalexitIconContainer {
    width: 100% !important;
  }

  .barcodeSvgContainer {
  svg {
    width: 320px !important;
  }
  }
}

@media only screen and (max-width: 420px) {
  .barcodeSvgContainer {
    svg {
      width: 280px !important;
    }
    }
}

@media only screen and (max-width: 340px) {
  .barcodeSvgContainer {
    svg {
      width: 240px !important;
    }
    }
}

@media only screen and (max-width: 280px) {
  .barcodeSvgContainer {
    svg {
      width: 200px !important;
    }
    }
}

.barcodeModalContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding:20px;
  margin:20px;
  align-items: center;
}

.barcodeContainer {
  background-color: white;
  padding:20px;
  margin: 20px; 
  width: 440px;
  height: fit-content;
}


.outterBarcodeModalContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.innerBarcodeModalContainer {
  width: 440px;
  display: flex;
  flex-direction: column;
}

.barcodeModalexitIconContainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-end !important; 
  // width: 440px;
}

.barcodeSvgContainer{
  align-self: center;
  svg {
    width: 420px;
  }
}

.barcodeModalTitle {
  align-self: center;
  font-weight: bold;
}

.barcodeCloseButton {
  align-self: center;
  padding-top: 20px;
}

.barcodeTextValue {
  text-align: center;
  padding-bottom: 15px;
}

.discountTextContainer {
  display: flex;
  flex-direction: column;
  max-height: 150px;
  overflow-y: auto;
}

.discountTextItem {
  text-align: center;
}

.discountTitleText {
  text-align: center;
  font-weight: bold;
}