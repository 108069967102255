@media screen and (max-width: 500px) {

  .clubLogoMember {
    height: 30px !important;
  }

 .myClubsContainer {
    width: 90% !important;
    padding-left: 25px ;
    padding-right: 25px;
  } 

  .myStoresContainer {
    width: 90% !important;
    padding-left: 25px ;
    padding-right: 25px;
  }

  .employeeCardWrapper {
    width: 90% !important;
    padding-left: 25px ;
    padding-right: 25px;
  }


  .instructionText {
    width: 90% !important;
  }
}

@media screen and (max-width: 400px) {

  .clubLogoMember {
    height: 20px !important;
  }

  .userBarcodeContainer {
    svg {
      width: 320px;
    }
  }
}

@media screen and (max-width: 350px) {

  .userBarcodeContainer {
    svg {
      width: 300px;
    }
  }
}

@media screen and (max-width: 320px) {

  .userBarcodeContainer {
    svg {
      width: 280px;
    }
  }
}

.memberContent {
  display: flex;
  flex-direction: column;
  // flex: 1.2;
}

.memberImageContainer {
  flex: 1; 
  display: flex;
  flex-direction: column;
}



.logoContainerMember {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
 
}


.clubLogoMember {
  width: auto;
  height: 46px;
}



.memberBackground {
  height: 100%;
  width: 100%;
}

.memberInfoContainer{
  // flex: 1; 
  display: flex;
  flex-direction: column;
  padding-left: 20px;
  padding-right: 20px;
  align-items: center;
}

.dateContainer {
  display: flex;
  flex-direction: row;

  .weekday{
    font-weight: bold;
    padding-right: 8px;
    color: rgb(36, 44, 73);
    font-size: 24px;
    text-align: center;
    letter-spacing: -0.6px;
    line-height: 36px;
  }

  .date {

    padding-right: 8px;
    color: rgb(36, 44, 73);
    font-size: 24px;
    text-align: center;
    letter-spacing: -0.6px;
    line-height: 36px;
  }




}

.badgesContainer {
  display: flex;
  flex-direction: row;
}

.storeBadge {
  width: auto;
  height: 40px;
 }

 .storeBadgeAndroid {
  width: auto;
  height: 58px;
 }

 .couponTextContainer {
   display: flex;
   flex-direction: row;
   align-items: center;
   font-size: 22px;
 }

 .couponText {
   padding-left: 3px;
   font-weight: bold;
 }

 .instructionText {
   text-align: center;
 }

 .instructions {
  color: rgb(36, 44, 73);
  font-size: 14px;
  font-weight: normal;
  text-align: center;
  letter-spacing: -0.35px;
  line-height: 20px;
}

.linkToTerms {
  padding-bottom: 20px;
  padding-top: 5px;
}

.myClubsContainer {
  width: 500px;
  padding-bottom: 20px;
}

.clubTitleContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.clubTitleSectionText {
  font-size: 14px;
  font-weight: normal;
  text-transform: uppercase;
  letter-spacing: 0px;
  line-height: 20px;
}

.clubTitleText {
  color: #11448F;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0px;
  line-height: 20px;
/*   text-decoration: underline; */
}

.myClublistItem {
  padding-top: 10px;
  padding-bottom: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.barcodeTextValue {
  text-align: center;
  padding-bottom: 15px;
}

.employeeCardContainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
 
}

.employeeCardWrapper {
  padding-top: 10px;
  padding-bottom: 20px;
  width: 500px;
}


.myStoresContainer {
  width: 500px;
  padding-bottom: 20px;
}

.storesTitleContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.storesTitleSectionText {
  font-size: 14px;
  font-weight: normal;
  text-transform: uppercase;
  letter-spacing: 0px;
  line-height: 20px;
}


.storeTitleText {
  color: #11448F;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0px;
  line-height: 20px;
  cursor: default;
/*   text-decoration: underline; */
}