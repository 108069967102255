@media screen and (max-width: 800px) {
  .innerWrapperFeedback {
    width: 100% !important;
  }

  .feedbackButtonPLaceholder-desktop {
    flex: 0 !important;
  }
}

.feedbackPageWrapper {
  flex: 1;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.feedbackPageContentContainer {
  flex: 1;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.feedbackCenterContainer {
  display: flex;
  justify-content: center;
  padding-top: 10px;
  padding-bottom: 10px;
}

.noFeedbackMessage {
  font-size: 18px;
  background-color: #ededed;
  padding: 25px;
  text-align: center;
}

.feedbackPageContentContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.sendFeedbackButton {
  flex: 1;
  max-width: 400px;
  width: 100%;
}

.feedbackElementWrapper {
  padding-top: 10px;
  display: flex;
  justify-content: center;
  width: 100%;
}

.feedbackElementWrapper-textfield {
  padding-top: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
}

.feedbackElementWrapper-buttons {
  padding-top: 30px;
  display: flex;
  justify-content: center;
  width: 100%;
}

.feedbackFormStyle {
  min-height: 150px;
  width: 100%;
}

.innerWrapperFeedback {
  width: 700px;
}

.feedbackFormPageContentContainer {
  flex: 1;
  padding-bottom: 20px;
  padding-top: 20px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.feedbackFormButtonContainer {
  display: flex;
  flex-direction: row;
  flex: 1;
}

.feedbackButtonWrapper {
  display: flex;
  flex-direction: row;
  flex: 1;

  .sendButtonContainer {
    margin-left: 5px;
    flex: 1;
  }

  .cancelButtonContainer {
    margin-right: 5px;
    flex: 1;
  }
}

.feedbackButtonPLaceholder-desktop {
  flex: 1;
}

.svgStarStyle {
  width: 46px !important;
  height: 46px !important;
}

.styledRatingWrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 40px;
  padding-bottom: 40px;
  border-style: solid;
  border-width: 1px;
  border-radius: 10px;
  border-color: rgba(0, 0, 0, 0.23);
}

.tyMessageContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  align-items: center;
}

.tyMessageTextContainer {
  padding-top: 30px;
}

.tyMessageTextStyle {
  text-align: center;
}

.tyMessageButtonContainer {
  padding-top: 25px;
}


.feedbackElementWrapper-customQuestionnaire {
  display: flex;
  justify-content: center;
  width: 100%;
  flex-direction: column;
}

.customQuestionnaireContainer {
  display: flex;
  flex-direction: column;
}

.customQuestionnairItem-wrapper {
  margin-top: 30px;
  width: 100%;
}

.customQuestionnaireSelectContainer {
  width: 100%;
}

.radioFormChoiceContainer-feedback {
  display: flex;
  flex-direction: column;
}

.customQuestionLabelText {
  color: black !important
}

.customQuestionLabelText-freeText {
  color: black !important;
  padding-bottom: 5px;
}

.customQuestionCheckBoxRow {
  display: flex;
  flex-direction: row;
}

.customQuestionCheckboxText {
  align-self: center;
  display: flex;
  flex-direction: row;
}


