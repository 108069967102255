@import "../../components/SharedStyles/tooltip.scss";

.loginPageConatiner {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  .loginContainer {
    min-width: 20vw;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .loginHeaderText {
      font-family: "DIN-Pro";
      font-size: 30px;
      text-align: left;
      margin-bottom: 30px;
      margin-top: 50px;
      align-self: center;
    }

    .loginInput {
      margin-bottom: 16px;
      width: 100%;
    }
    .loader {
      color: $white;
    }
    .disabledButtonWithNoStyle {
      background-color: $sanMarino;
    }

    .submitButton {
      margin-top: 20px;
    }
  }

  .smsCodeInput {
    min-width: 20vw;
  }
}

.MuiOutlinedInput-root {
  border-radius: 10px !important;
}

.MuiButton-containedPrimary {
  border-radius: 25px !important;
  box-shadow: none !important;
}

.registerLinkContainer {
  //  position: absolute;
  display: flex;
  flex-direction: row;
  padding-bottom: 30px;
  padding-top: 20px;
}

.loginPhoneContainer {
  display: flex;
  flex-direction: row;
}

.landCodeInputLogin {
  width: 90px;
  padding-right: 20px !important;
}
