@media only screen and (max-width: 760px) {
  .termPageContainer {
    padding-left: 20px;
    padding-right: 20px;
  }
}

@media only screen and (max-width: 500px) {
  .termTitle {
    font-size: 16px !important;
  }

  .termShortText {
    font-size: 14px !important;
  }

  .modalTitleText{
    font-size: 24px !important;
  }

  .disclaimerText{
    font-size: 24px !important;
   line-height: normal;
  }

  .deleteText {
    font-size: 14px !important;
  }

  .readMoreText {
    font-size: 14px !important;
  }
}

.termPageContainer {
/*   background-color: white;
  padding:20px;
  margin: 20px;
  overflow-y: scroll !important;
  height: 85%; */
}

.exitIconContainerTerms {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end !important;
}


.termLineContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.termTitle {
  color: rgb(36, 44, 73);
  font-size: 18px;
  font-weight: bold;
  letter-spacing: -0.11px;
  line-height: 24px;
  padding-bottom:2px;
  padding-top: 20px;
  display: flex;
  justify-content: center;
  text-align: center;
}

.termShortText {
  color: rgb(36, 44, 73);
  font-size: 16px;
  letter-spacing: -0.1px;
  line-height: 24px;
  text-align: center;
}

.readMoreTextButton {
  display: flex !important;
  flex-direction: row !important;
  justify-content: center !important;
  align-content: center !important;
  width: 100%;
}

.readMoreText {
  color: rgb(2, 78, 162);
  font-size: 16px;
  letter-spacing: -0.4px;
  line-height: 20px;
  padding-top: 5px;
  text-decoration: underline;
  text-align: center;
}

.termButtonsContainer{
  padding-top: 30px;
  padding-bottom: 20px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}


.loadingContainer {
  position: absolute;
  top:40;
  left: 40;
  display: flex;
  flex-direction: row;
}

.loadingText {
  padding-left: 5px;
}

.loadingPlaceholder {
  height: 26px;
}

.tooltipContainer{
  position:absolute;
  max-width: 700px;
  padding-right: 30px;
  background-color: black;
  opacity: 90%;
  border-radius: 10px;
}

.toolTipText {  
  padding: 5px;
  color: 	rgb(255,255,255)
}

.termWrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.termWrapperContainer {
  width: 700px;
}

.modalTitleText {
  color: rgb(36, 44, 73);
  font-size: 30px;
  letter-spacing: -0.75px;
  line-height: 16px;
  padding-top: 10px;
}


.objectWrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.objectContainer {
  width: 700px;
  display: flex;
  justify-content: center;
}

.deleteText {
  color: rgb(227, 15, 26);
  font-size: 16px;
  font-weight: 500;
  text-decoration: underline;
  letter-spacing: -0.4px;
  line-height: 20px;
  padding-top: 45px;
  padding-bottom: 40px;
  font-family: 'DIN-Pro';
}

.readMoreAndToggleContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
}


.disabledElement{
  display: flex;
  justify-content: center;
  opacity: 0.6;
  padding-top: 10px;
}

.enabledToggle{
  padding-top: 10px;
  display: flex;
  justify-content: center;
  flex-direction: row;
}

.disclaimerText {
  color: rgb(36, 44, 73);
  font-size: 30px;
  font-weight: bolder;
  letter-spacing: -0.75px;
   line-height: 24px;
  padding-top: 40px;
  text-align: center;
}

.userForm-enter {
  opacity: 0;
  transform: scale(0.9);
}
.userForm-enter-active {
  opacity: 1;
  transform: translateX(0);
  transition: opacity 300ms, transform 300ms;
}
.userForm-exit {
  opacity: 1;
}
.userForm-exit-active {
  opacity: 0;
  transform: scale(0.9);
  transition: opacity 300ms, transform 300ms;
}

.userInfoContainer {
  padding-bottom: 20px;
}

.termTextContainer {
  background-color: #f5f5f5;
  padding: 8px;
}

