
@media only screen and (max-width: 500px) {
    .couponImage {
      width: 100% !important;
    }
}

@media only screen and (max-width: 510px) {
  .innerChild{
      padding: 20px
    }

    .couponCodeContainer {
      padding-left: 10px;
    }

}



.couponsContainer {
  width: 500px;
  // overflow: hidden;
}

.couponsWrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.couponsInnerContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.noCouponsMessage {
  font-size: 18px;
  background-color: #EDEDED;
  padding: 25px;
  text-align: center;
}

.couponListContainer {
  display: flex;
  flex-direction: column;
  padding-top: 20px;
}

.couponsPageTitle {
  color: rgb(36, 44, 73);
  font-size: 30px;
  letter-spacing: -0.75px;
  line-height: 16px;
  padding-top: 10px;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.couponStyle {
  margin-bottom: 65px;
  background-color: #024ea2;
  padding: 0;
  margin-top: 0;
  color: white;
  position: relative;
  -webkit-box-shadow: 2px 2px 8px 3px rgba(0,0,0,0.1); 
  box-shadow: 2px 2px 8px 3px rgba(0,0,0,0.1);
}



.coupontTitle {
  font-size: 16px;
  font-weight: bold;
  padding-left: 15px;
  padding-right: 15px;
}

.couponContainer {
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 10px;
  padding-bottom: 15px;
  position: relative;
}



.couponTitleText {
  padding:0;
  margin: 0;
  font-size: 14px;
  font-family: 'DIN-Pro';
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 0px;
  line-height: 20px;
}

.couponCodeText {
  color: rgb(255, 255, 255);
  font-size: 18px;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 1.13px;
  line-height: 23px;
}



.couponDatesContainer {
  display: flex;
  flex-direction: column;
  border-top: .5px dotted white;
  padding-top: 5px;
  padding-bottom: 5px;
}

.couponDateItem {
  display: flex;
  flex-direction: row;
  font-size: 14px;
  justify-content: flex-start;
  font-weight: normal;
  letter-spacing: 0px;
  line-height: 20px;
}

.dateText {
  padding-left: 3px;
}

.innerChild {
  flex: 1;
}


.couponImage {
  height: auto;
  width: 500px; 

}



.fullCircle {
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background-color:white;
  margin-right: 7px;
  margin-top: 10px;
  margin-bottom: 10px;
  border-width: 1px;
  border-style: solid;
  border-color: #024ea2;
}

.fullCircleActive {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: #024ea2;
  margin-right: 7px;
  margin-top: 10px;
  margin-bottom: 10px;
}


.arrowStyleL {
  color: white;
  font-size: 22px;
  position:absolute;
  left: 2px;
  top: 12.5px;
  opacity: 1;
}

.arrowStyleL::hover {
 opacity: 0.5 !important;
}


.arrowStyleR {
  color: white;
  font-size: 22px;
  position:absolute;
  right: 2px;
  top: 12.5px;
  transform: rotate(180deg);
  opacity: 1;
}

.arrowStyleR::hover {
  opacity: 0.5 !important;
}


.cardShadow {
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px !important;
}


.customPagerContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
background-color: white;

}

.arrowStyleRButton {
  position: absolute;
  z-index: 2;
  top: calc(50% - 15px);
  width: 25px;
  height: 50px;
  cursor: pointer;
  background: #024ea2;
  border-bottom-left-radius: 25px;
  border-top-left-radius: 25px;
  border-style: none;
  opacity: 0.5;
  right: 0;
}

.arrowStyleRButtonDeactivated {
  position: absolute;
  z-index: 2;
  top: calc(50% - 15px);
  width: 25px;
  height: 50px;
  cursor: pointer;
  background: #a2a2a2;
  border-bottom-left-radius: 25px;
  border-top-left-radius: 25px;
  border-style: none;
  opacity: 0.5;
  right: 0;
}



.arrowStyleRButton:hover {
  opacity: 1;
}

.arrowStyleLButton {
  position: absolute;
  z-index: 2;
  top: calc(50% - 15px);
  width: 25px;
  height: 50px;
  cursor: pointer;
  background: #024ea2;
  border-bottom-right-radius: 25px;
  border-top-right-radius: 25px;
  border-style: none;
  opacity: 0.5;
  left: 0;
}

.arrowStyleLButtonDeactivated {
  position: absolute;
  z-index: 2;
  top: calc(50% - 15px);
  width: 25px;
  height: 50px;
  cursor: pointer;
  background: #a2a2a2;
  border-bottom-right-radius: 25px;
  border-top-right-radius: 25px;
  border-style: none;
  opacity: 0.5;
  left: 0;
}


.arrowStyleLButton:hover {
  opacity: 1;
}







