@media screen and (max-width: 500px) {

  .clubLogo {
    height: 30px !important;
  }
}

@media screen and (max-width: 300px) {

  .clubLogo {
    height: 20px !important;
  }
} 



.layoutContainerPolicy{
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  background-image: linear-gradient(180deg, #242b49, white);
}

.logoWrapPolicy {
  flex: 0; 
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding-top: 15px;
  padding-bottom: 25px;
}

.policyContainer {
  flex: 1; 
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  justify-content: space-around;
}

.policyTextContainer {
  background-color: white;
  margin-left: 30px;
  margin-right: 30px;
  padding:15px;
  overflow: auto;
}



.clubLogo {
  width: auto;
  height: 46px;
}

.logoContainer {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
}





