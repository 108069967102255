@media only screen and (max-width: 760px) {
  .topbarItemsContainer {
    display: none !important;
  }

  .mobileMeniuButtonContainer{
    display: flex !important;
    padding-top: 15px !important;
    position: absolute !important;
  }
}

@media only screen and (min-width: 761px) {
  .mobileMeniuButtonContainer{
    display: none !important;
  }
}

// for topbar meniu - disable min width 
@media (min-width: 600px) {
  .MuiTab-root {
    min-width: 50px !important;
}
}



.topbarContainer {
  width: 100%;

}

.topbarWrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.topbarItemsContainer {
  position: absolute;
  top: 0;
  width: 100%;
}

.mobileMeniuButtonContainer {
  display: none;

}

.mobileMenuIcon {
  color: white;
  width: 22px;
}

.mobileMenuText {
  color: rgb(255, 255, 255);
  font-size: 16px;
  font-weight: bold;
  text-align: right;
  letter-spacing: 0.5px;
  font-family: 'DIN-Pro';
  padding-left: 5px;
  padding-bottom: 2px;
  padding-top: 1px;
}


.topbarBackground-enter {
  transform: translateY(-100%);

}
.topbarBackground-enter-active {
  transform: translateY(0%);
  transition:  transform 400ms ease-in;
}
.topbarBackground-exit {
  transform: translateY(0);
}
.topbarBackground-exit-active {
  transform: translateY(-100%);
  transition:  transform 400ms ease-out;
}


.topbarSmall-enter {
  position: absolute;
  top: 0;
  width: 100%

}
.topbarSmall-enter-active {
  position: absolute;
  top: 0;
  width: 100%
}
.topbarSmall-exit {
  display: none !important;
}
.topbarSmall-exit-active {
  display: none !important;
}


 .Mui-selected {
  color: red !important;

}