.registerTitle {
color: rgb(36, 44, 73);
font-size: 30px;
font-family: 'DIN-Pro';
font-weight: 500;
letter-spacing: -0.75px;
font-weight: bold;
}

.signUpContainer {
  padding-left: 15px;
  padding-right: 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.formContainer {
  display: flex;
  flex-direction: column;
  max-width: 800px;
  width: 100%;
}

.phoneAndEmailContainer {
  display: flex;
 flex-direction: column;
}

.registerTitle{
  margin-top: 50px;
}

.phoneCodeContainer {
  padding-right: 5px !important;
}

.inputFieldStyle {
  padding-bottom: 15px !important;
}

.allCheckBoxContainer {
  padding-top: 15px;
}

.checkBoxContainer {
  display: flex;
  flex-direction: row;
}

.phonesContainer {
  display: flex;
  flex-direction: row;
  padding-bottom: 15px !important;
}

.landCodeInput {
  width: 75px;
  padding-right: 10px !important;
}

.landCodePlus {
  align-self: center;
  font-size: 18px;
  padding-right: 3px;
}

.phoneNumberInput {
  flex: 1
}

.astreixInfo {
  padding-top: 5px;
  font-size: 14px;
}

.registerContainer{
  padding-top: 30px;
  padding-bottom: 5px;
  display: flex;
  flex: 1;
  width: 100%;
  flex-direction: row;
  justify-content: center;
}

.registerButton{
  flex: 1;
  max-width: 400px;
  width: 100%;
}

.conditionText {
  align-self: center;
  display: flex;
  flex-direction: row;
}

.conditionsError {
  color: #f44336;
  font-size: 14px;
}

.MuiButton-containedSecondary {
  border-radius: 20px !important;
  border-width: 0 !important;
}

.containerLinkToSignIn {
  padding-top: 10px;
  padding-bottom: 20px;
  display: flex;
  flex-direction: row;

}

.linkToSignIn {
  padding-left: 3px !important;
 font-family: 'DIN-Pro';
 font-size: 16px;
 text-decoration-line: underline !important;
 color:#11448F !important
}

.MuiButtonGroup-root {
  background-color: #EDEDED !important;
  border-radius: 25px !important;
}

.showModalLink {
  align-self: center;
  padding-left: 3px !important;
  font-family: 'DIN-Pro';
  font-size: 16px;
  text-decoration-line: underline !important;
  color:#11448F !important
}

.toggleGenderContainer {
  max-width: 400px;
}

.agreeAllText {
  align-self: center;
}
