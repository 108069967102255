.noUserinfoText {
  color: #BEBEBE
}

.userNameText {
  padding-top: 5px;
  font-weight: bold;
}

.editUserButton {
  width: 100%;
}