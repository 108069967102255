@font-face {
  font-family: 'DIN-Pro';
  src: local('DIN-Pro'), url(./assets/fonts/DINPro-Regular.otf) format('opentype');}

body {
  margin: 0;
  font-family: 'DIN-Pro',-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: 'DIN-Pro',source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Remove arrows from numeric input */
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}


:root {
  --toastify-color-error: #FF4761 !important;
  --toastify-color-success: #00A982 !important;
  --toastify-color-warning: #F6DA6F !important;

  --toastify-icon-color-success: var(--toastify-color-success);
  --toastify-icon-color-warning: var(--toastify-color-warning);
  --toastify-icon-color-error: var(--toastify-color-error);

  --toastify-color-progress-success: var(--toastify-color-success);
  --toastify-color-progress-warning: var(--toastify-color-warning);
  --toastify-color-progress-error: var(--toastify-color-error);
}

.MuiButton-containedSecondary {
  box-shadow: none;
}


