@media only screen and (max-width: 500px) {
  .followStoresModalContent {
    width: 90% !important;
    max-height: 90%;
    overflow-y: auto;
  }

  .followStoreContainerexitIconContainer {
    width: 100% !important;
  }

  .paddingContainer-placeholder-container {
    max-width: 90% !important;
    max-height: 90% !important;
    min-height: 300px;
    padding: 20px !important;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.followStoreContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  outline: none;
}

.followStoresModalContent {
  background-color: #ffffff;
  max-width: 500px;
  outline: none;

  .paddingContainer {
    padding: 20px;
    padding-bottom: 40px;
  }
}

.followStoreContainerexitIconContainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-end !important;
}

.followStoreModalTitle {
  font-size: 22px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  font-weight: 700;
}

.followStorExitIconContainer {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;

  .cancelIcon {
    width: 30px;
    height: 30px;
  }
}

.followStoreCheckBoxContainer {
  display: flex;
  flex-direction: row;
}

.multipleStoresModalContainer {
  width: 100%;

  .infoText {
    padding-left: 10px;
    text-align: center;
  }
}

.multipleStoreSelectScreen {
  overflow-y: auto;
  max-height: 400px !important;
  margin-bottom: 25px;

  .choiceText {
    vertical-align: bottom;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}

.buttonWrapContainer {
  display: flex;
  justify-content: center;
}

.buttonsWrapModal {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 100%;
  align-self: center;
  align-content: center;
  max-width: 240px;
}

.followSingleStoreTitle {
  font-weight: 700;
  font-size: 16px;
  text-align: center;
}

.singleStoreInfoText {
  text-align: center;
}

.noStoresInFollowStoreModalText {
  padding-bottom: 30px;
  font-size: 16px;
  text-align: center;
}

.suggestedStoresLoaderContainer {
  height: 40px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.infoText-bottom {
  padding-left: 10px;
  text-align: center;
  padding-bottom: 25px;
}

.paddingContainer-placeholder-container {
  padding: 200px;
  padding-left: 220px;
  padding-right: 220px;
}
