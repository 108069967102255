.rulesContainer {
  background-color: white;
  padding:20px;
  margin: 20px;
  overflow-y: auto !important;
  height: 85%;
}

.exitIconContainer {
  position: absolute !important;
  top: 35px !important;
  right: 35px !important;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end !important;
}