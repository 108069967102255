@import "../../components/SharedStyles/tooltip.scss";

@media screen and (max-width: 500px) {
  .loginPageConatinerUnsub {
    .loginHeaderTextUnsub {
      font-size: 32px !important;
    }
  }
}

@media screen and (max-width: 400px) {
  .loginPageConatinerUnsub {
    .loginHeaderTextUnsub {
      font-size: 30px !important;
    }
  }
}

.loginPageWrapper {
  flex: 1;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.loginPageConatinerUnsub {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  .loginContainerUnsub {
    min-width: 20vw;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 20px;
    .loginHeaderTextUnsub {
      font-family: "DIN-Pro";
      font-size: 36px;
      text-align: center;
      margin-bottom: 26px;
      align-self: center;
    }

    .loginInput {
      margin-bottom: 16px;
      width: 100%;
    }
    .loader {
      color: $white;
    }
    .disabledButtonWithNoStyle {
      background-color: $sanMarino;
    }

    .submitButton {
      margin-top: 20px;
    }
  }

  .smsCodeInput {
    min-width: 20vw;
  }
}

.MuiOutlinedInput-root {
  border-radius: 10px !important;
}

.MuiButton-containedPrimary {
  border-radius: 25px !important;
  box-shadow: none !important;
}

.registerLinkContainer {
  display: flex;
  flex-direction: row;
  padding-bottom: 30px;
  padding-top: 20px;
}

.loginPhoneContainer {
  display: flex;
  flex-direction: row;
}

.landCodeInputLogin {
  width: 90px;
  padding-right: 20px !important;
}

.logoWrapUnsub {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.unsubInfoContainer {
  padding-bottom: 30px;
  text-align: center;

  .unsubTextLine {
    padding-top: 10px;
  }
}
