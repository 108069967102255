@media screen and (max-width: 500px) {

  .clubLogo {
    height: 30px !important;
  }

  .loginHeaderTextUnsub {
    font-size: 32px !important;
  }
}

@media screen and (max-width: 400px) {
  .loginHeaderTextUnsub {
    font-size: 30px !important;
  }

  .unsubPageTextContainer {
    min-height: 100vw;
    display: flex;
    flex-direction: column;
    justify-content: space-between; 
  }
}

@media screen and (max-width: 300px) {
  .clubLogo {
    height: 20px !important;
  }
} 


.layoutContainer{
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.logoWrap {
  flex: 1; 
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.unsubPageWrapper{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
}

.unsubPageContainer {
  max-width: 500px;
}

.unsubPageTextContainer {
  padding-top: 5px;
  padding-bottom: 10px;
}

.loginHeaderTextUnsub {
  font-family: 'DIN-Pro';
  font-size: 36px;
  text-align: center;
  margin-bottom: 26px;
  align-self: center;
  padding-left: 20px;
  padding-right: 20px;
}

.unsubInfoTextContainer {
  padding-left: 20px;
  padding-right: 20px;
}

.clubLogo {
  width: auto;
  height: 46px;
}

.logoContainer {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.unsubPageButtonContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
}

.unsubButtonStyle {
  margin-top: 20px;
  width: 100%;
  max-width: 400px;
}

.unsubPageOuterContainer {
  height: 100%;
}





